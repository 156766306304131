// About.js
import React from 'react';

const About = () => {
  return (
    <div className='container'>
      <h2>About</h2>
      <p>
        This is a simple OpenLayers map viewer application built with React. It allows users to view maps and interact with geographic data.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula massa nec nisi faucibus, at convallis ligula luctus. 
        Aliquam posuere purus a nibh sodales, in blandit eros malesuada. Nullam id malesuada elit. Aliquam erat volutpat. 
        Fusce eget eros semper, pharetra enim in, rutrum ligula. Aenean vel urna eget urna tincidunt cursus nec a lectus. 
        Integer laoreet orci in arcu pellentesque, non tempus nisi lacinia. 
      </p>
    </div>
  );
};

export default About;
