import React, { useState, useEffect } from "react";
import { v5 as uuidv5 } from 'uuid';
import { Card, CardBody, CardTitle, Row, Col, Label, Input } from 'reactstrap';
import Map from "../../components/Map";
import { Layers, TileLayer, ImageLayer } from "../../components/Layers";
import { bing, imageWMS, xyz } from "../../components/Source";
import { fromLonLat, transformExtent } from "ol/proj";
import IntlText from "../../components/IntlText";
import {
  Controls,
  InitialZoomControl,
  ZoomControl,
  ScaleLineControl,
  BasemapControl,
  FeatureInfoControl,
  MouseInfoControl,
  LegendControl,
} from "../../components/Controls";



const MapViewer = (props) => {
  const [jsonData, setJsonData] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(null);

  const [selectedValues, setSelectedValues] = useState({});
  const [disabledInputs, setDisabledInputs] = useState({});

  const [activeLayers, setActivelayers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/config.json');
      const data = await response.json();

      const defaultValues = {};
      Object.keys(data.menu).forEach(menuKey => {
        defaultValues[menuKey] = data.menu[menuKey][0].value;
      });
      setSelectedValues(defaultValues);

      const initialDisabledInputs = {};
      Object.keys(data.menu).forEach(menuKey => {
        initialDisabledInputs[menuKey] = false;
      });
      setDisabledInputs(initialDisabledInputs);

      setJsonData(data);
      setCenter(fromLonLat(data.view.center));
      setZoom(data.view.zoom);

    };

    fetchData();

  }, []);

  useEffect(() => {

    if (selectedValues.reportingPeriod === '_1981_2010') {
      setDisabledInputs(prevState => ({
        ...prevState,
        presentationMethod: true,
        climateScenario: true
      }));

      if (selectedValues.presentationMethod !== '' || selectedValues.climateScenario !== '') {
        setSelectedValues(prevState => ({
          ...prevState,
          presentationMethod: '',
          climateScenario: ''
        }));
      }

    } else {
      setDisabledInputs(prevState => ({
        ...prevState,
        presentationMethod: false,
        climateScenario: false
      }));
    }

    let climateScenario = selectedValues.climateScenario;
    let presentationMethod = selectedValues.presentationMethod;

    if (selectedValues.reportingPeriod === '_1981_2010') {
      climateScenario = '';
      presentationMethod = '';
    }

    setActivelayers([
      {
        "title": `cli_ras${selectedValues.climaticParameter}${climateScenario}${selectedValues.reportingPeriod}${presentationMethod}`,
        "opacity": 0.7,
        "visible": true,
        "extent": [
          -180,
          -90,
          180,
          90
        ],
        "minZoom": 1,
        "maxZoom": 28,
        "source": {
          "url": "https://gis.climateacademy.gr/geoserver/gis/wms",
          "params": {
            "LAYERS": `cli_ras${selectedValues.climaticParameter}${climateScenario}${selectedValues.reportingPeriod}${presentationMethod}`,
            "STYLES": "",
            "VERSION": "1.3.0"
          },
          "serverType": "geoserver"
        }
      }
    ]);
  }, [selectedValues]);

  const onChange = (event, menuKey) => {
    const selectedValue = event.target.value;

    setSelectedValues(prevState => ({
      ...prevState,
      [menuKey]: selectedValue
    }));

  }

  const createUUIDFromString = (inputString) => {
    return uuidv5(inputString, uuidv5.URL);
  };

  return (
    <div className="app">

      <div style={{ margin: 10 }}>
        <Card>
          <CardBody>
            <Row>
              {jsonData ? Object.keys(jsonData.menu).map((e, i) =>
                <Col key={`index_option_${e}_${i}`}>
                  <Label for={e}><IntlText id={e} /></Label>
                  <Input id={e} type="select" value={selectedValues[e]} disabled={disabledInputs[e]} onChange={(event) => onChange(event, e)} >
                    {
                      jsonData.menu[e].map(o => <option value={o.value} key={`index_option_${e}_${o.value}`}>{<IntlText id={o.title} />}</option>)
                    }
                  </Input>
                </Col>
              )
                : null}
            </Row>
          </CardBody>
        </Card>
      </div>

      {jsonData ? (
        <div className="content">
          <div className="map-root-div" style={isSidebarOpen ? { width: 'calc(100% - 250px)', float: 'right' } : { width: '100%', float: 'unset' }}>
            <Map center={center} zoom={zoom} loggedin={props.loggedin}>
              <Layers key={`map-key-${Math.random()}`}>
                <TileLayer title={'Road'} name={'Roadmap'} baseLayer={true} visible={true} source={xyz({ url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}', attributions: 'el', maxZoom: 24 })} zIndex={0} logo={"/img/road.png"} />
                <TileLayer title={'Satellite'} name={'Satellite'} baseLayer={true} visible={false} source={xyz({ url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}', attributions: 'el', maxZoom: 24 })} zIndex={0} logo={"/img/aerial.png"} />
                <TileLayer title={'Hybrid'} name={'Hybrid'} baseLayer={true} visible={false} source={xyz({ url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}', attributions: 'el', maxZoom: 24 })} zIndex={0} logo={"/img/hybrid.png"} />
                {
                  activeLayers.map((e, i) => <ImageLayer
                    key={`layer-${i}`}
                    name={e.title}
                    baseLayer={false}
                    visible={e.visible}
                    extent={transformExtent(e.extent, 'EPSG:4326', 'EPSG:3857')}
                    opacity={e.opacity}
                    minZoom={e.minZoom}
                    maxZoom={e.maxZoom}
                    source={imageWMS({
                      url: e.source.url,
                      params: e.source.params
                    })} />)
                }
              </Layers>
              <Controls>
                <InitialZoomControl center={center} zoom={zoom} />
                <ZoomControl />
                <ScaleLineControl />
                <BasemapControl />
                <LegendControl activeLayer={activeLayers[0]} style={{ top: 'unset', bottom: '2.5em', right: 'unset', left: '0.5em' }} />
                <MouseInfoControl />
              </Controls>
            </Map>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default MapViewer;