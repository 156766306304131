import React, { useContext, useEffect, useState } from "react";
import ImageWMS from 'ol/source/ImageWMS';
import MapContext from "../Map/MapContext";


const LegendControl = ({ activeLayer, sld = '', style }) => {
  const [legend, setLegend] = useState(null);
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const wmsSource = new ImageWMS({
      url: activeLayer.source.url,
      params: activeLayer.source.params,
      ratio: 1,
      serverType: 'geoserver',
    });
    const graphicUrl = wmsSource.getLegendUrl(map.getView().getResolution());
    setLegend(graphicUrl + '&STYLE=' + sld);

    map.getView().on('change:resolution', resolution);

    return () => map.un('singleclick', resolution);

  }, [map, activeLayer]);

  const resolution = (event) => {
    const resolution = event.target.getResolution();
    const wmsSource = new ImageWMS({
      url: activeLayer.source.url,
      params: activeLayer.source.params,
      ratio: 1,
      serverType: 'geoserver',
    });
    const graphicUrl = wmsSource.getLegendUrl(resolution);
    setLegend(graphicUrl + '&STYLE=' + sld);
  }


  return <div className="ol-control ol-custom-zoomto-div" style={style}>
    <div style={{ padding: '5px', borderRadius: '4px', maxHeight: '50vh', overflow: 'auto' }}>
      <img src={legend} alt="legend" />
    </div>
  </div>;
};

export default LegendControl;