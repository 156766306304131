import React, { useContext, useEffect, useState } from "react";
import ImageWMS from 'ol/source/ImageWMS';
import Overlay from 'ol/Overlay';
import MapContext from "../Map/MapContext";

const MouseInfoControl = () => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const divElement = document.createElement("div");
    divElement.style.fontSize = "1.5em";
    divElement.style.fontWeight = "bold";
    divElement.style.textShadow = "0 0 5px white, 0 0 10px white";

    const overlay = new Overlay({
      element: divElement,
      positioning: 'bottom-center',
      offset: [0, 0]
    });

    map.addOverlay(overlay);

    const showInfo = (evt) => {
      const viewResolution = map.getView().getResolution();
      const coordinate = evt.coordinate;
      const element = overlay.getElement();
      const fetchPromises = [];

      map.getLayers().forEach(function (layer) {
        if (layer.getSource() instanceof ImageWMS) {
          var source = layer.getSource();
          var url = source.getFeatureInfoUrl(coordinate, viewResolution, 'EPSG:3857', {
            'INFO_FORMAT': 'application/json'
          });

          if (url) {
            fetchPromises.push(
              fetch(url)
                .then(function (response) {
                  return response.json();
                })
                .then(function (json) {
                  return json.features;
                })
            );
          }
        }
      });

      Promise.all(fetchPromises)
        .then(function (results) {
          let combinedResults = results.flat();
          if (combinedResults && combinedResults.length > 0 && combinedResults[0].properties['GRAY_INDEX'] >= 0 && combinedResults[0].properties['GRAY_INDEX'] < 20000) {
            element.innerHTML = Math.round(combinedResults[0].properties['GRAY_INDEX']);
            overlay.setPosition(coordinate);
            element.style.display = 'block';
          } else {
            element.style.display = 'none';
          }
        })
        .catch(function (error) {
          console.error('Error fetching data:', error);
        });

    };

    map.on('pointermove', showInfo);

    return () => {
      map.un('pointermove', showInfo);
      map.removeOverlay(overlay);
    };

  }, [map]);

  return null;
};

export default MouseInfoControl;
